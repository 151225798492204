import { useNavigate } from "react-router-dom";
import LessonCardContainer from "../../Components/LessonCardContainer/LessonCardContainer";
import SectionContainer from "../../Components/SectionContainer/SectionContainer";
import CardContainer from "../../Components/UI/CardContainer/CardContainer";
import ViewAllButton from "../../Components/UI/ViewAllButton/ViewAllButton";
import BackIcon from "../../icons/BackIcon";
import BoardIcon from "../../icons/BoardIcon";
import VideoIcon from "../../icons/VideoIcon";
import classes from "./Lessons.module.css";
import { useEffect, useState } from "react";
import { useLessons } from "../../context/LessonsContext";
import {
  categorizeItemsToday,
  formatLessonDate,
} from "../../utilities/functions";

const upcomingLessons = [
  {
    icon: <VideoIcon />,
    type: "Live lesson",
    name: "Joseph Myers",
    subject: "Mathematical Literacy Term 3",
    dateTime: "Tuesday 16 Feb - 1PM - 2PM",
    backgroundColor: "#E3F0E7",
    color: "#43B061",
  },
  {
    icon: <BoardIcon />,
    type: "Interactive lesson",
    name: "Joseph Myers",
    subject: "NS & Tech: Matter and Materials",
    dateTime: "Tuesday 16 Feb - 1PM - 2PM",
    backgroundColor: "#F6EEDF",
    color: "#CA9835",
  },
];

const pastLessons = [
  {
    icon: <VideoIcon />,
    type: "Live lesson",
    name: "Joseph Myers",
    subject: "Mathematical Literacy Term 1",
    dateTime: "Tuesday 16 Feb - 1PM - 2PM",
    backgroundColor: "#E3F0E7",
    color: "#43B061",
  },
  {
    icon: <VideoIcon />,
    type: "Live lesson",
    name: "Joseph Myers",
    subject: "Mathematical Literacy Term 2",
    dateTime: "Tuesday 16 Feb - 1PM - 2PM",
    backgroundColor: "#E3F0E7",
    color: "#43B061",
  },
];

const Lessons = () => {
  let navigate = useNavigate();

  const [pastLessons, setPastLessons] = useState([]);
  const [ongoingLessons, setOngoingLessons] = useState([]);
  const [upcomingLessons, setUpcomingLessons] = useState([]);

  const { lessons } = useLessons();

  console.log("DO WE HAVE THE LESSONS", lessons);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const { pastItems, ongoingItems, upcomingItems } =
      categorizeItemsToday(lessons);
    setPastLessons(pastItems);
    setOngoingLessons(ongoingItems);
    setUpcomingLessons(upcomingItems);
  }, [lessons]);

  return (
    <div className={classes.wrapper}>
      <div
        className={`d-flex align-items-baseline gap-3 mb-4 ${classes.backButton}`}
        onClick={handleGoBack}
        role="button"
      >
        <BackIcon />
        <h2>Lessons</h2>
      </div>

      {ongoingLessons.length > 0 ? (
        <SectionContainer heading="Ongoing lessons" sort="Show: All types">
          {ongoingLessons.map((item, i) => (
            <LessonCardContainer
              key={i}
              icon={
                item.lesson_type === "Live Lesson" ? (
                  <VideoIcon />
                ) : (
                  <BoardIcon />
                )
              }
              name={`${item.user_created.first_name} ${item.user_created.last_name}`}
              subject={`${item.subject} ${item.lesson_name}`}
              dateTime={formatLessonDate(
                item.date_scheduled,
                item.start_time,
                item.end_time
              )}
              pillColor={
                item.lesson_type === "Live Lesson" ? "#43B061" : "#CA9835"
              }
              pillbackgroundColor={
                item.lesson_type === "Live Lesson" ? "#E3F0E7" : "#F6EEDF"
              }
              lessonType={item.lesson_type}
            />
          ))}
          <ViewAllButton title="Go to my timetable" onClick={() => navigate("/time-table")}/>
        </SectionContainer>
      ) : (
        <CardContainer className={classes.cardContainer}>
          <h1>You have no lessons scheduled right now</h1>
        </CardContainer>
      )}
      {upcomingLessons.length > 0 ? (
        <SectionContainer heading="Upcoming lessons" sort="Show: All types">
          {upcomingLessons.map((item, i) => (
            <LessonCardContainer
              key={i}
              icon={
                item.lesson_type === "Live Lesson" ? (
                  <VideoIcon />
                ) : (
                  <BoardIcon />
                )
              }
              name={`${item.user_created.first_name} ${item.user_created.last_name}`}
              subject={`${item.subject} ${item.lesson_name}`}
              dateTime={formatLessonDate(
                item.date_scheduled,
                item.start_time,
                item.end_time
              )}
              pillColor={
                item.lesson_type === "Live Lesson" ? "#43B061" : "#CA9835"
              }
              pillbackgroundColor={
                item.lesson_type === "Live Lesson" ? "#E3F0E7" : "#F6EEDF"
              }
              lessonType={item.lesson_type}
            />
          ))}
          <ViewAllButton title="Go to my timetable"  onClick={() => navigate("/time-table")}/>
        </SectionContainer>
      ) : (
        <CardContainer className={classes.cardContainer}>
          <h1>You have no upcoming lessons</h1>
        </CardContainer>
      )}
      {pastLessons.length > 0 ? (
        <SectionContainer heading="Past lessons" sort="Show: All types">
          {pastLessons.map((item, i) => (
            <LessonCardContainer
              key={i}
              icon={
                item.lesson_type === "Live Lesson" ? (
                  <VideoIcon />
                ) : (
                  <BoardIcon />
                )
              }
              name={`${item.user_created.first_name} ${item.user_created.last_name}`}
              subject={`${item.subject} ${item.lesson_name}`}
              dateTime={formatLessonDate(
                item.date_scheduled,
                item.start_time,
                item.end_time
              )}
              pillColor={
                item.lesson_type === "Live Lesson" ? "#43B061" : "#CA9835"
              }
              pillbackgroundColor={
                item.lesson_type === "Live Lesson" ? "#E3F0E7" : "#F6EEDF"
              }
              lessonType={item.lesson_type}
            />
          ))}
          <ViewAllButton title="Go to my timetable"  onClick={() => navigate("/time-table")}/>
        </SectionContainer>
      ) : (
        <CardContainer className={classes.cardContainer}>
          <h1>You have no past lessons</h1>
        </CardContainer>
      )}
    </div>
  );
};

export default Lessons;
