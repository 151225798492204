import { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import classes from "./Layout.module.css";
import MenuIcon from "../../icons/MenuIcon";
import BellIcon from "../../icons/BellIcon";
import ChatIcon from "../../icons/ChatIcon";
import IconContainer from "../../Components/UI/IconContainer/IconContainer";
import Dashboard from "../Dashboard/Dashboard";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Lessons from "../Lessons/Lessons";
import Library from "../Library/Library";
import Timetable from "../Timetable/Timetable";
import Assessments from "../Assessments/Assessments";
import Performance from "../Performance/Performance";
import Resources from "../Resources/Resources";
import Points from "../Points/Points";
import Account from "../Account/Account";
import Help from "../Help/Help";
import AllGrades from "../Performance/AllGrades/AllGrades";
import AssessmentSubject from "../Assessments/AssessmentSubject/AssessmentSubject";
import ReourceCategory from "../Resources/ResourceCategory/ReourceCategory";
import ChangeInformation from "../Account/ChangeInformation/ChangeInformation";
import ChangePIN from "../Account/ChangePIN/ChangePIN";
import ParentAccount from "../Account/ParentAccount/ParentAccount";
import { useLessons } from "../../context/LessonsContext";
import { useAuth } from "../../context/AuthContext";
import { fetchLessons } from "../../services/lessons";

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);

  let navigate = useNavigate();

  const { user } = useAuth();
  const { setLessons } = useLessons();

  useEffect(() => {
    const getLessons = async () => {
      const userLessons = await fetchLessons(user.id);
      setLessons(userLessons);
    };

    getLessons();
  }, [user]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.main}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`d-flex align-items-center justify-content-between ${classes.header}`}
      >
        <IconContainer onClick={toggleSidebar}>
          <MenuIcon />
        </IconContainer>
        <div className={classes.logoWrapper}>
          <img src="/thutotime-logo.svg" />
        </div>
        <div className="d-flex align-items-center gap-3">
          <IconContainer>
            <ChatIcon />
          </IconContainer>
          <IconContainer>
            <BellIcon />
          </IconContainer>
          <div
            className={classes.userImage}
            style={{
              backgroundImage: `url(${
                user.avatar ? user.avatar : "/avatar.svg"
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
            }}
            onClick={() => navigate("/account")}
            role="button"
          ></div>
        </div>
      </div>
      <div className={classes.content}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/library" element={<Library />} />
          <Route path="/time-table" element={<Timetable />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route
            path="/assessments/assessment-subject"
            element={<AssessmentSubject />}
          />
          <Route path="/performance" element={<Performance />} />
          <Route path="/performance/all-grades" element={<AllGrades />} />
          <Route path="/resources" element={<Resources />} />
          <Route
            path="/resources/resource-category"
            element={<ReourceCategory />}
          />
          <Route path="/points" element={<Points />} />
          <Route path="/account" element={<Account />} />
          <Route
            path="/account/change-information"
            element={<ChangeInformation />}
          />
          <Route path="/account/change-pin" element={<ChangePIN />} />
          <Route path="/account/parent" element={<ParentAccount />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
