// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BasicCalendar_eventDots__H4aSt {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  
  .BasicCalendar_eventDot__grnnE {
    height: 6px;
    width: 6px;
    background-color:  var(--primary-color);
    border-radius: 50%;
    margin: 0 2px;
    flex-shrink: 0;
  }`, "",{"version":3,"sources":["webpack://./src/Components/BasicCalendar/BasicCalendar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,UAAU;IACV,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;IACb,cAAc;EAChB","sourcesContent":[".eventDots {\n    display: flex;\n    justify-content: center;\n    margin-top: 5px;\n  }\n  \n  .eventDot {\n    height: 6px;\n    width: 6px;\n    background-color:  var(--primary-color);\n    border-radius: 50%;\n    margin: 0 2px;\n    flex-shrink: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventDots": `BasicCalendar_eventDots__H4aSt`,
	"eventDot": `BasicCalendar_eventDot__grnnE`
};
export default ___CSS_LOADER_EXPORT___;
