import classes from './ConfirmButton.module.css'

const ConfirmButton = ({buttonText, onClick, style}) => {
    return(
        <div className={`mt-5 d-flex align-items-center justify-content-center ${classes.continueButton}`} style={style} onClick={onClick} role='button'>
            {buttonText}
        </div>
    )
}

export default ConfirmButton