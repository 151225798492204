// src/services/lessons.js
import { createItem, readItem, readItems, rest, updateItem } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';
import { Bounce, toast } from 'react-toastify';

const client = directus.with(rest());


export const fetchClassesById = async (classId) => {
  try {

    console.log("SINGLE CLASS ID", classId)

    const classes = await client.request(readItem('classes', classId, {fields:["*.*"]}));

    console.log("SINGLE CLASS", classes)
    return classes;
  } catch (error) {
    throw new Error('Failed to fetch classes: ' + error.message);
  }
};

