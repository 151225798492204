import { useNavigate } from "react-router-dom";
import CardContainer from "../../Components/UI/CardContainer/CardContainer";
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer";
import SectionTitleContainer from "../../Components/UI/SectionTitleContainer/SectionTitleContainer";
import CameraIcon from "../../icons/CameraIcon";
import classes from "./Account.module.css";
import { useEffect, useState } from "react";
import AlertDialog from "../../Components/UI/AlertDialog/AlertDialog";
import { useAuth } from "../../context/AuthContext";
import { fetchClassesById } from "../../services/classes";
import { fetchLearner } from "../../services/learners";
import { getOrdinal } from "../../utilities/functions";

const pointsData = [
  {
    title: "Change my information",
    link: "change-information",
  },
  {
    title: "Change my PIN",
    link: "change-pin",
  },
  {
    title: "Parent account",
    link: "parent",
  },
  {
    title: "Copyright notice",
    link: "copyright",
  },
  {
    title: "Terms and conditions",
    link: "terms",
  },
  {
    title: "Close account",
    link: "close-account",
  },
  {
    title: "Log out",
    link: "logout",
  },
];

const Account = () => {
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [closeAccountDialog, setCloseAccountDialog] = useState(false);

  const [classDetails, setClassDetails] = useState({});

  const { handleLogout, user } = useAuth();

  let navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const logoutHandler = () => {
    setLogoutDialog(false);
    handleLogout();
  };

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const learnerRes = await fetchLearner(user.id);

        const classRes = await fetchClassesById(learnerRes[0].class_id);

        console.log("LESSONS BY CLASS", classRes);

        setClassDetails(classRes);
      } catch (err) {
        console.log(err);
      }
    };

    getUserDetails();
  }, [user]);

  return (
    <div className={classes.wrapper}>
      <HeadingContainer heading="My account" onClick={handleGoBack} />
      <div className="mb-4 d-flex align-items-center justify-content-center">
        <div
          className={`${classes.profileWrapper} d-flex flex-column align-items-center justify-content-center`}
        >
          <div
            className={classes.userImage}
            style={{
              backgroundImage: `url(${
                user.avatar ? user.avatar : "/avatar.svg"
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
            }}
          >
            <div
              className={`${classes.iconContainer} d-flex align-items-center justify-content-center`}
            >
              <CameraIcon />
            </div>
          </div>
          <h3>
            {user.first_name} {user.last_name} (ID: {user.school_id})
          </h3>
          <h5>
            {getOrdinal(classDetails.grade)} grade,{" "}
            {classDetails.school?.name && classDetails.school?.name + ","}{" "}
            {classDetails.school?.district &&
              classDetails.school?.district + ","}{" "}
            {classDetails.school?.circuit}
          </h5>
        </div>
      </div>
      {pointsData.map((item, i) => (
        <CardContainer
          className={classes.pointsCard}
          key={i}
          role="button"
          onClick={() =>
            item.link === "logout"
              ? logoutHandler()
              : item.link === "close-account"
              ? setCloseAccountDialog(true)
              : navigate(item.link)
          }
        >
          <div>{item.title}</div>
        </CardContainer>
      ))}
      <AlertDialog
        isOpen={logoutDialog || closeAccountDialog}
        titleText={logoutDialog ? "Log out" : "Close account"}
        confirmButtonText={logoutDialog ? "Log out" : "Yes, close account"}
        cancelButtonText={logoutDialog ? "Cancel" : "No, Keep my account"}
        onCancel={() =>
          logoutDialog ? setLogoutDialog(false) : setCloseAccountDialog(false)
        }
        onConfirm={() => (logoutDialog ? navigate("/login") : "")}
      >
        <div className={`my-4 ${classes.descText}`}>
          {logoutDialog
            ? "Are you sure you want to log out of this account?"
            : "Are you sure you want to close this account?"}
        </div>
      </AlertDialog>
    </div>
  );
};

export default Account;
