import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import classes from './BasicCalendar.module.css'; // Import the CSS file

const BasicCalendar = ({date, handleDateChange, lessons}) => {

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();


  const [events, setEvents] = useState([
    { date: new Date(2024, month, 14), title: 'Meeting' },
    { date: new Date(2024, month, 14), title: 'Conference' },
    { date: new Date(2024, month, 14), title: 'Conference' },
    { date: new Date(2024, month, 15), title: 'Lunch' },
    { date: new Date(2024, month, 15), title: 'Dinner' },
    { date: new Date(2024, month, 20), title: 'Lunch' },
    { date: new Date(2024, month, 21), title: 'Dinner' },
    { date: new Date(2024, month, 23), title: 'Dinner' },
  ]);



  const getTileContent = ({ date, view }) => {
    if (view === 'month') {
      const dayEvents = lessons.filter(
        (event) => {
          const eventDate = new Date(event?.date_scheduled)

          return eventDate?.toDateString() === date?.toDateString()}
      );
      return (
        <div className={classes.eventDots}>
          {dayEvents.map((event, index) => (
            <div key={index} className={classes.eventDot}></div>
          ))}
        </div>
      );
    }
  };

  return (
    <div>
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileContent={getTileContent}
      />
    </div>
  );
};

export default BasicCalendar;