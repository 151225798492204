import Cookies from 'js-cookie';

// Function to set token
export const setToken = (token, expiresIn) => {
  Cookies.set('authToken', token, { expires: expiresIn / (24 * 60 * 60) }); // expiresIn should be in seconds
};

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const dayNames = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

export const getDateTime = () => {
    const currentDate = new Date();

  const year = currentDate.getFullYear();
  const monthIndex = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const dayIndex = currentDate.getDay();
  const date = currentDate.getDate();
  let hours = currentDate.getHours();
  const minutes =  currentDate.getHours()
  const period = hours >= 12 ? 'PM' : 'AM';
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const dateWithSuffix = getDayWithSuffix(date);

  return {year, month: monthNames[monthIndex], day: dayNames[dayIndex], date: dateWithSuffix, hours, minutes, period}
}


export const getDateTimeCalendar = (date, time) => {
  const currentDate = new Date(date);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();
  const hours = splitTimeString(time).hours;
  const minutes = splitTimeString(time).minutes;

  return new Date(year, month, day, hours, minutes);
};


export function getOrdinal(number) {
  if (isNaN(+number)) {
    return number;
  }
  const j = number % 10;
  const k = Math.floor((number % 100) / 10);

  if (k === 1) {
    return number + "th"; // special case for 11th, 12th, 13th
  }

  if (j === 1) {
    return number + "st";
  }

  if (j === 2) {
    return number + "nd";
  }

  if (j === 3) {
    return number + "rd";
  }

  return number + "th";
}

function splitTimeString(timeString) {
    if (!timeString) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  
    return { hours, minutes, seconds };
}

function getDayWithSuffix(day) {
  if (day > 3 && day < 21) return `${day}th`; // Covers 4th to 20th
  switch (day % 10) {
    case 1: return `${day}st`;
    case 2: return `${day}nd`;
    case 3: return `${day}rd`;
    default: return `${day}th`;
  }
}

export function convertTimeTo12HourFormat(timeString) {

  let [hours, minutes, seconds] = timeString.split(':');
  

  hours = parseInt(hours, 10);
  
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  hours = hours % 12 || 12; 
  
  return {timeOnly: `${hours}:${minutes}`, time: `${hours} ${ minutes > 0 ? ": " + minutes : ""} ${ampm}` };
}


export function checkIfCurrentDateTimeInRange(item) {

  const now = new Date();

  const currentDate = now.toISOString().split('T')[0];

  
  const currentTime = now.toTimeString().slice(0, 5);


  if (item.date_scheduled !== currentDate) {
    return false; 
  }

  const currentTimeDate = new Date(`1970-01-01T${currentTime}:00`); // Time for comparison
  const startTimeDate = new Date(`1970-01-01T${item.start_time}`);
  const endTimeDate = new Date(`1970-01-01T${item.end_time}`);

  return currentTimeDate >= startTimeDate && currentTimeDate <= endTimeDate;
}


export function getRemainingItemsToday(items) {

  const now = new Date();

  const currentDate = now.toISOString().split('T')[0];
  
  const currentTime = now.toTimeString().slice(0, 5);

  const remainingItems = items.filter(item => {

    if (item.date_scheduled !== currentDate) {
      return false; 
    }

    const currentTimeDate = new Date(`1970-01-01T${currentTime}:00`); 
    const endTimeDate = new Date(`1970-01-01T${item.end_time}:00`);    

    return currentTimeDate <= endTimeDate;
  });

  return remainingItems.length;
}



export function categorizeItemsToday(items) {

  const now = new Date();

  const currentDate = now.toISOString().split('T')[0];

  const currentTime = now.toTimeString().slice(0, 5);

  const currentTimeDate = new Date(`1970-01-01T${currentTime}:00`);


  const pastItems = [];
  const ongoingItems = [];
  const upcomingItems = [];

  items.forEach(item => {
    if (item.date_scheduled === currentDate) {

      const startTimeDate = new Date(`1970-01-01T${item.start_time}`);
      const endTimeDate = new Date(`1970-01-01T${item.end_time}`);

      if (currentTimeDate > endTimeDate) {
        pastItems.push(item);
      } else if (currentTimeDate < startTimeDate) {
        upcomingItems.push(item);
      } else {
        ongoingItems.push(item);
      }
    }
  });

  return { pastItems, ongoingItems, upcomingItems };
}

export function formatLessonDate(date, startTime, endTime) {

  const today = new Date(date);

  const startDate = new Date(today.toDateString() + " " + startTime);
  const endDate = new Date(today.toDateString() + " " + endTime);

  const options = { weekday: "long", day: "numeric", month: "short" }; // e.g. "Tuesday 16 Feb"
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    startDate
  );

  const startTimeFormatted = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(startDate);

  const endTimeFormatted = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(endDate);

  return `${formattedDate} - ${startTimeFormatted} - ${endTimeFormatted}`;
}

