import { useState } from "react";
import classes from "./Login.module.css";
import BackIcon from "../../icons/BackIcon";
import { useNavigate } from "react-router-dom";
import InputField from "../../Components/UI/InputField/InputField";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  let navigate = useNavigate();
  // const { fetchLessons } = useContext(LessonsContext);

  const [userId, setUserId] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const { handleLogin } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the fields are empty
    if (!userId || !pin) {
      setError("User ID and PIN cannot be empty");
      return;
    }

    const fullUserId = `${userId}@thutotime.com`;

    try {
      await handleLogin(fullUserId, pin);
      navigate("/");
    } catch (err) {
      setError("Invalid User ID or PIN");
    }
  };

  const onSubmit = async () => {
    // try {
    //   await handleLogin(email, password);
    //   navigate('/');
    // } catch (error) {
    //   console.error('Login failed', error);
    // }
  };

  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-between ${classes.main}`}
    >
      <img src="/thuto-login.svg" />

      <div className={`d-flex flex-column mt-5 ${classes.inputWrapper}`}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div className={`d-flex flex-column gap-5`}>
          <InputField
            label="Your ID*"
            type="text"
            placeholder="Enter Your ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <InputField
            label="Your PIN*"
            type="password"
            placeholder="Enter Your PIN"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            showEye={true}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <div
            className={classes.linkText}
            onClick={() => navigate("/register")}
            role="button"
          >
            Register new account
          </div>
          <div
            className={classes.linkText}
            onClick={() => navigate("/forgot-pin")}
            role="button"
          >
            Forgot my Password
          </div>
        </div>
      </div>

      <div
        className={`mt-5 d-flex align-items-center justify-content-center ${classes.continueButton}`}
        onClick={handleSubmit}
        role="button"
      >
        Continue
      </div>
    </div>
  );
};

export default Login;
