// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_main__c0Oja{
    /* margin-top: 60px; */
}

.Dashboard_timeColumn__4ZS7\\+, .Dashboard_detailsColumn__B8uPh{
    width: 35px;
    height: 101px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #FFFFFF;
}

.Dashboard_timeColumn__4ZS7\\+{
    flex-shrink: 0;
}

.Dashboard_detailsColumn__B8uPh h6{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 0;
}

.Dashboard_detailsColumn__B8uPh h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.Dashboard_detailsColumn__B8uPh{
    width: 100%;
    padding: 15px 20px 15px 20px;
}

.Dashboard_todayLessonsContainer__DZcYM{
    max-height: 400px;
    overflow-y: auto;
}

.Dashboard_pillContainer__2IU-j{
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 10px;
    gap: 10px;
    border-radius: 100px;
    background: #E3F0E7;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
}

.Dashboard_timeText__eF0k9{
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

.Dashboard_cardContainer__O8H56{
    padding: 10px;
}

.Dashboard_cardContainer__O8H56 h3{
    font-size: 16px;
    font-weight: 275;
    line-height: 28px;
    text-align: center;
    margin: 0;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,SAAS;IACT,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".main{\n    /* margin-top: 60px; */\n}\n\n.timeColumn, .detailsColumn{\n    width: 35px;\n    height: 101px;\n    padding: 5px;\n    border-radius: 10px;\n    box-shadow: 0px 2px 2px 0px #0000000D;\n    background: #FFFFFF;\n}\n\n.timeColumn{\n    flex-shrink: 0;\n}\n\n.detailsColumn h6{\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n    text-align: left;\n    margin: 0;\n}\n\n.detailsColumn h3{\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n}\n\n.detailsColumn{\n    width: 100%;\n    padding: 15px 20px 15px 20px;\n}\n\n.todayLessonsContainer{\n    max-height: 400px;\n    overflow-y: auto;\n}\n\n.pillContainer{\n    width: fit-content;\n    padding: 3px 10px;\n    gap: 10px;\n    border-radius: 100px;\n    background: #E3F0E7;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 15px;\n}\n\n.timeText{\n    font-size: 10px;\n    font-weight: 400;\n    line-height: 15px;\n    text-align: left;\n}\n\n.cardContainer{\n    padding: 10px;\n}\n\n.cardContainer h3{\n    font-size: 16px;\n    font-weight: 275;\n    line-height: 28px;\n    text-align: center;\n    margin: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Dashboard_main__c0Oja`,
	"timeColumn": `Dashboard_timeColumn__4ZS7+`,
	"detailsColumn": `Dashboard_detailsColumn__B8uPh`,
	"todayLessonsContainer": `Dashboard_todayLessonsContainer__DZcYM`,
	"pillContainer": `Dashboard_pillContainer__2IU-j`,
	"timeText": `Dashboard_timeText__eF0k9`,
	"cardContainer": `Dashboard_cardContainer__O8H56`
};
export default ___CSS_LOADER_EXPORT___;
