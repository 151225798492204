// src/MyCalendar.js
import React, {useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomToolbar from './Components/CustomToolbar/CustomToolbar';
import DayViewCustomEvent from './Components/CustomEvent/DayViewCustomEvent';
import CustomToolbarComponent from './Components/CustomToolbarComponent/CustomToolbarComponent';
import { useLessons } from '../../context/LessonsContext';
import { getDateTimeCalendar } from '../../utilities/functions';



const localizer = momentLocalizer(moment);



const CalendarComponent = ({showMonth, className, toggleDetails}) => {


  const { lessons } = useLessons()

  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    // Do something with the event
    toggleDetails()
  };
  
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth(); // Adding 1 because getMonth() returns 0 for January
  const day = currentDate.getDate();


  const events = [
    {
      title: 'Chemistry',
      start: new Date(2024, month, day, 18, 0),
      end: new Date(2024, month, day, 19, 0),
      allDay: false,
    },
    {
      title: 'Mathematical Literacy Term 1',
      start: new Date(2024,month, day, 14, 0),
      end: new Date(2024, month, day, 15, 0),
      allDay: false,
    },
    {
      title: 'Mathematical Literacy Term 3',
      start: new Date(2024, month, day, 0, 0),
      end: new Date(2024, month, day, 1, 0),
      allDay: false,
    },
  ];

  const [myEvents, setMyEvents] = useState(events);




  const transformedLessons = lessons.map(lesson => ({
    title: lesson.subject,
    lessonName: lesson.lesson_name,
    lessonsType: lesson.lesson_type,
    grade: lesson.class?.grade,
    start: getDateTimeCalendar(lesson.date_scheduled, lesson.start_time),
    end: getDateTimeCalendar(lesson.date_scheduled, lesson.end_time),
    createdBy: lesson.user_created?.first_name + " " + lesson.user_created?.last_name,
    allDay: false,
    meetingID: lesson.stream_id,
    attendeePW: lesson.ap
  }));



  return (
    <div className={`custom-calendar ${className}`}>
    <Calendar
      localizer={localizer}
      events={transformedLessons}
      // startAccessor="start"
      // endAccessor="end"
      // style={{ height: 500 }}
      views={['month', 'week', 'day']}  // Exclude 'agenda' from the views
      components={{
        toolbar: showMonth ? CustomToolbarComponent : CustomToolbar ,  // Use custom toolbar
        day: {
          event: DayViewCustomEvent  // Use custom event component for day view only
        },
      }}
      onSelectEvent={toggleDetails}
    />
  </div>
  );
};

export default CalendarComponent