// src/Sidebar.js

import React, { useEffect, useState } from "react";
import classes from "./Sidebar.module.css";
import IconContainer from "../UI/IconContainer/IconContainer";
import BackIcon from "../../icons/BackIcon";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../icons/DashboardIcon";
import LessonsIcon from "../../icons/LessonsIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import PerformanceIcon from "../../icons/Performance";
import AssessmentsIcon from "../../icons/AssessmentsIcon";
import ClassesIcon from "../../icons/ClassesIcon";
import ResourceIcon from "../../icons/ResourceIcon";
import RequestIcon from "../../icons/Request";
import CardContainer from "../UI/CardContainer/CardContainer";
import LogoutIcon from "../../icons/LogoutIcon";
import LibraryIcon from "../../icons/LibraryIcon";
import UserIcon from "../../icons/UserIcon";
import StarIcon from "../../icons/StarIcon";
import IconCard from "../UI/IconCard/IconCard";
import AlertDialog from "../UI/AlertDialog/AlertDialog";
import { useAuth } from "../../context/AuthContext";
import { fetchLearner } from "../../services/learners";
import { fetchClassesById } from "../../services/classes";
import { getOrdinal } from "../../utilities/functions";

const navLinks = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon active={true} />,
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    icon: <LessonsIcon />,
    activeIcon: <LessonsIcon active={true} />,
    title: "Lessons",
    link: "/lessons",
  },
  {
    icon: <LibraryIcon />,
    activeIcon: <LibraryIcon active={true} />,
    title: "Library",
    link: "/library",
  },
  {
    icon: <CalendarIcon />,
    activeIcon: <PerformanceIcon active={true} />,
    title: "My Timetable",
    link: "/time-table",
  },
  {
    icon: <AssessmentsIcon />,
    activeIcon: <AssessmentsIcon active={true} />,
    title: "My Performance",
    link: "/performance",
  },
  {
    icon: <PerformanceIcon />,
    activeIcon: <PerformanceIcon active={true} />,
    title: "My Assessments",
    link: "/assessments",
  },
  {
    icon: <ResourceIcon />,
    activeIcon: <ResourceIcon active={true} />,
    title: "Resources",
    link: "/resources",
  },
  {
    icon: <StarIcon />,
    activeIcon: <StarIcon active={true} />,
    title: "My Points",
    link: "/points",
  },
  {
    icon: <UserIcon />,
    activeIcon: <UserIcon active={true} />,
    title: "My Account",
    link: "/account",
  },
  {
    icon: <RequestIcon />,
    activeIcon: <RequestIcon active={true} />,
    title: "Help Section",
    link: "/help",
  },
  {
    icon: <LogoutIcon />,
    activeIcon: <LogoutIcon active={true} />,
    title: "Log Out",
    link: "logout",
  },
];

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  const [logoutDialog, setLogoutDialog] = useState(false);
  const [classDetails, setClassDetails] = useState({});

  const { handleLogout, user } = useAuth();

  const logoutHandler = () => {
    toggleSidebar(false);
    setLogoutDialog(true);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const learnerRes = await fetchLearner(user.id);

        const classRes = await fetchClassesById(learnerRes[0].class_id);

        console.log("LESSONS BY CLASS", classRes);

        setClassDetails(classRes);
      } catch (err) {
        console.log(err);
      }
    };

    getUserDetails();
  }, [user]);
  return (
    <>
      {isOpen && (
        <div className={classes.overlay} onClick={toggleSidebar}></div>
      )}
      <div className={`${classes.sidebar} ${isOpen ? classes.open : ""}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div></div>
          <IconContainer onClick={toggleSidebar}>
            <BackIcon color="#43B061" />
          </IconContainer>
        </div>

        <div
          className={`${classes.profileWrapper} d-flex flex-column align-items-center mb-4`}
        >
          <div
            className={classes.userImage}
            style={{
              backgroundImage: `url(${
                user.avatar ? user.avatar : "/avatar.svg"
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
            }}
          ></div>
          <h3>
            {user.first_name} {user.last_name}
          </h3>
          <h5>
            {getOrdinal(classDetails.grade)} grade,{" "}
            {classDetails.school?.name && classDetails.school?.name + ","}{" "}
            {classDetails.school?.district &&
              classDetails.school?.district + ","}{" "}
            {classDetails.school?.circuit}
          </h5>
        </div>

        <div className={classes.wrapper}>
          <div className={classes.navWrapper}>
            {navLinks.map((item, i) => (
              <CardContainer className="mb-3">
                <div
                  className={`${classes.navContainer} `}
                  onClick={() => {
                    if (item.link === "logout") {
                      logoutHandler();
                    } else {
                      navigate(item.link);
                      toggleSidebar();
                    }
                  }}
                  role="button"
                >
                  <div className="d-flex align-items-center gap-4">
                    <IconCard>{item.icon}</IconCard>
                    <div className={classes.titleText}>{item.title}</div>
                  </div>{" "}
                  <div></div>
                </div>
              </CardContainer>
            ))}
          </div>
        </div>
      </div>
      <AlertDialog
        isOpen={logoutDialog}
        titleText="Log out"
        confirmButtonText="Log out"
        cancelButtonText="Cancel"
        onCancel={() => {
          setLogoutDialog(false);
          toggleSidebar(true);
        }}
        onConfirm={handleLogout}
      >
        <div className={`my-4 ${classes.descText}`}>
          Are you sure you want to log out of this account?
        </div>
      </AlertDialog>
    </>
  );
};

export default Sidebar;
