// src/Sidebar.js

import React, { useState } from "react";
import classes from "./LessonDetails.module.css";
import IconContainer from "../UI/IconContainer/IconContainer";
import BackIcon from "../../icons/BackIcon";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../icons/DashboardIcon";
import LessonsIcon from "../../icons/LessonsIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import PerformanceIcon from "../../icons/Performance";
import AssessmentsIcon from "../../icons/AssessmentsIcon";
import ClassesIcon from "../../icons/ClassesIcon";
import ResourceIcon from "../../icons/ResourceIcon";
import RequestIcon from "../../icons/Request";
import CardContainer from "../UI/CardContainer/CardContainer";
import LogoutIcon from "../../icons/LogoutIcon";
import LibraryIcon from "../../icons/LibraryIcon";
import UserIcon from "../../icons/UserIcon";
import StarIcon from "../../icons/StarIcon";
import IconCard from "../UI/IconCard/IconCard";
import ConfirmButton from "../UI/ConfirmButton/ConfirmButton";
import { useAuth } from "../../context/AuthContext";

const navLinks = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon active={true} />,
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    icon: <LessonsIcon />,
    activeIcon: <LessonsIcon active={true} />,
    title: "Lessons",
    link: "/lessons",
  },
  {
    icon: <LibraryIcon />,
    activeIcon: <LibraryIcon active={true} />,
    title: "Library",
    link: "/library",
  },
  {
    icon: <CalendarIcon />,
    activeIcon: <PerformanceIcon active={true} />,
    title: "My Timetable",
    link: "/time-table",
  },
  {
    icon: <AssessmentsIcon />,
    activeIcon: <AssessmentsIcon active={true} />,
    title: "My Performance",
    link: "/performance",
  },
  {
    icon: <PerformanceIcon />,
    activeIcon: <PerformanceIcon active={true} />,
    title: "My Assessments",
    link: "/assessments",
  },
  {
    icon: <ResourceIcon />,
    activeIcon: <ResourceIcon active={true} />,
    title: "Resources",
    link: "/resources",
  },
  {
    icon: <StarIcon />,
    activeIcon: <StarIcon active={true} />,
    title: "My Points",
    link: "/points",
  },
  {
    icon: <UserIcon />,
    activeIcon: <UserIcon active={true} />,
    title: "My Account",
    link: "/account",
  },
  {
    icon: <RequestIcon />,
    activeIcon: <RequestIcon active={true} />,
    title: "Help Section",
    link: "/help",
  },
  {
    icon: <LogoutIcon />,
    activeIcon: <LogoutIcon active={true} />,
    title: "Log Out",
    link: "logout",
  },
];

const LessonDetails = ({ children, isOpen, toggleDetails, lessonDetails }) => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const logoutHandler = () => {
    navigate("/login");
  };

  console.log("meeting ID", lessonDetails);
  const [isSending, setIsSending] = useState(false);

  const submitHander = async () => {
    if (lessonDetails.lessonsType === "Interactive Lesson") {
      return;
    }
    try {
      setIsSending(true);
      const bbbResponse = await fetch(`https://red.thutomdm.com/join`, {
        method: "POST",
        headers: {
          // 'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: `${user.first_name} ${user.last_name}`,
          meetingID: lessonDetails.meetingID,
          password: lessonDetails.attendeePW,
        }),
      });

      const result = await bbbResponse.json();

      window.open(
        // `https://bbb.thutotime.com/bigbluebutton/api/join?fullName=Teacher_One&meetingID=${lessonDetails.meetingID}&password=${lessonDetails.ap}&redirect=true&checksum=${result.checkSum}`,
        `https://bbb.thutotime.com/bigbluebutton/api/join?fullName=${`${user.first_name}_${user.last_name}`}&meetingID=${lessonDetails.meetingID}&password=${lessonDetails.attendeePW}&redirect=true&checksum=${result.checkSum}`,
        "_blank"
      );

      console.log("Response URL", result);
    } catch (err) {
      console.log("JOIN EERROR", err);
    }
    setIsSending(false);
  };
  return (
    <>
      {isOpen && (
        <div className={classes.overlay} onClick={toggleDetails}></div>
      )}
      <div className={`${classes.sidebar} ${isOpen ? classes.open : ""}`}>
        <div className="d-flex align-items-center gap-3">
          <IconContainer onClick={toggleDetails}>
            <BackIcon color="#43B061" rotate={true} />
          </IconContainer>
          <div className={classes.lessonTitleWrapper}>
            <h3>{`${lessonDetails.title} ${lessonDetails.lessonName}`}</h3>
            <p>{lessonDetails.lessonsType} / {lessonDetails.title} / {lessonDetails.createdBy}</p>
          </div>
          
        </div>
        <ConfirmButton
          onClick={submitHander}
          buttonText={
            lessonDetails.lessonsType === "Interactive Lesson" ? "View" : "Join"
          }
          style={{ padding: "10px 0" }}
        />
        {children}
        
      </div>
    </>
  );
};

export default LessonDetails;
