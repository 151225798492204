import { useState } from "react"
import CalendarComponent from "../../Components/CalendarComponent/CalendarComponent"
import HeadingContainer from "../../Components/UI/HeadingContainer/HeadingContainer"
import InfoCard from "../../Components/UI/InfoCard/InfoCard"
import SearchBar from "../../Components/UI/SearchBar/SearchBar"
import classes from "./Library.module.css"
import { useNavigate } from "react-router-dom"
import LessonDetails from "../../Components/LessonDetails/LessonDetails"


const statisticsData = [
    {
        title: 'Mathematics',
        buttonText: '24 lessons'
    },
    {
        title: 'Geography',
        buttonText: '24 lessons'
    },
    {
        title: 'English',
        buttonText: '24 lessons'
    }
]

const statisticsData1 = [
    {
        title: 'Biology',
        buttonText: '24 lessons'
    },
    {
        title: 'Adv. Mathematics',
        buttonText: '24 lessons'
    },
    {
        title: 'Physics',
        buttonText: '24 lessons'
    },
]

const Library = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [lessonDetails, setLesssonDetails] = useState({})

  let navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleLessonDetails = () => {
    console.log("OPEN DETAILS");
    setIsOpen(!isOpen);
  };
    return(
        <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
            <HeadingContainer heading="Lesson library" onClick={handleGoBack}/>
            <SearchBar className={`mb-5`} placeholder="Search in library"/>
            </div>
            
            <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3 mb-3`}>
                {statisticsData.map((item, i) => (<InfoCard title={item.title} buttonText={item.buttonText}  color="#553E12" backgroundColor="#EFE3CA" key={i} className={classes.infoCard}/>))}
            </div>
            <div className={`${classes.statisticsContainer} d-flex align-items-center gap-3`}>
                {statisticsData1.map((item, i) => (<InfoCard title={item.title} buttonText={item.buttonText} color="#553E12" backgroundColor="#EFE3CA" key={i} className={classes.infoCard}/>))}
            </div>
            <CalendarComponent showMonth={true} className={`${classes.innerWrapper} mt-4`} toggleDetails={(event) => {setLesssonDetails(event); toggleLessonDetails()}}/>
            <LessonDetails isOpen={isOpen} toggleDetails={toggleLessonDetails} lessonDetails={lessonDetails}/>
        </div>
    )
}

export default Library