// src/services/lessons.js
import { createItem, readItems, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());

export const fetchLessons = async (userId) => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }

    const classes = await client.request(readItems('learners', {fields: ["*.*"], filter: {user_id: {_eq: userId}}}, {access_token: token} ));

    const ids = classes.map(item => item.class_id);

    const lessons = await client.request(readItems('lessons', {fields: ["*.*"], filter: {class: {_in: ids}}, sort: "start_time"}, {access_token: token} ));

    return lessons;
  } catch (error) {
    console.log(error)
    throw new Error('Failed to fetch lessons: ' + error.message);
  }
};

export const fetchLessonsByClass = async (classId) => {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }

    const lessons = await client.request(readItems('lessons',{filter: {class: {_eq: classId}}} ));

    console.log("LESSONSSSS", lessons)
    return lessons;
};

export const createLesson = async (lessonData) => {
    
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }
    const result = await client.request(createItem('lessons', lessonData, {access_token: token}));
    return result;
};