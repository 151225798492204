// src/services/lessons.js
import {createItem, readItems, readUser, readUsers, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());


export const fetchLearner = async (userId) => {
  try {
 
    const result = await client.request(readItems('learners',{filter: {user_id: userId}}));

    return result;
  } catch (error) {
    throw new Error('Failed to fetch search: ' + error.message);
  }
};
