import { useEffect, useState } from "react";
import ActivitiesCardContainer from "../../Components/ActivitiesCardContainer/ActivitiesCardContainer";
import BasicCalendar from "../../Components/BasicCalendar/BasicCalendar";
import HorizontalScrollContainer from "../../Components/HorizontalScrollContainer/HorizontalScrollContainer";
import PerformanceCardContainer from "../../Components/PerformanceCardContainer/PerformanceCardContainer";
import { useAuth } from "../../context/AuthContext";
import BoardIcon from "../../icons/BoardIcon";
import BookPlayIcon from "../../icons/BookPlayIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import EventsIcon from "../../icons/EventsIcon";
import GradeIcon from "../../icons/GradeIcon";
import VideoIcon from "../../icons/VideoIcon";

import classes from "./Dashboard.module.css";
import { fetchLessons } from "../../services/lessons";
import { useLessons } from "../../context/LessonsContext";
import { checkIfCurrentDateTimeInRange, convertTimeTo12HourFormat, getRemainingItemsToday } from "../../utilities/functions";
import CardContainer from "../../Components/UI/CardContainer/CardContainer";



const upcomingLessons = [
  {
    icon: <VideoIcon />,
    type: "Live lesson",
    name: "Teacher Name",
    subject: "English grammar",
    startTime: "8:00",
    endTime: "9:00",
    dateTime: "8AM - 9AM",
    backgroundColor: "#E3F0E7",
    color: "#43B061",
    active: true,
  },
  {
    icon: <VideoIcon />,
    type: "Live lesson",
    name: "Joseph Myers",
    subject: "Mathematical Literacy Term 1",
    startTime: "1:00",
    endTime: "2:00",
    dateTime: "8AM - 9AM",
    backgroundColor: "#E3F0E7",
    color: "#43B061",
    active: false,
  },
  {
    icon: <BoardIcon />,
    type: "Interactive lesson",
    name: "Joseph Myers",
    subject: "NS & Tech: Matter and Materials",
    startTime: "1:00",
    endTime: "2:00",
    dateTime: "8AM - 9AM",
    backgroundColor: "#F6EEDF",
    color: "#CA9835",
    active: false,
  },
];

const performance = [
  {
    title: "Next grade",
    subject: "Biology:",
    date: "15th Feb",
    borderColor: "#76A3CC",
  },
  {
    title: "Assignment",
    subject: "Biology",
    date: "15th Feb - A+ (96%)",
    borderColor: "#F32488",
  },
  {
    title: "Assignment",
    subject: "Biology",
    date: "15th Feb - A+ (96%)",
    borderColor: "#F32488",
  },
  {
    title: "Next grade",
    subject: "Biology:",
    date: "15th Feb",
    borderColor: "#76A3CC",
  },
];

const Dashboard = () => {
  const [date, setDate] = useState(new Date());

  const { lessons } = useLessons();

  const [selectedLesson, setSelectedLessons] = useState([]);

  console.log("SELECTED LESSONS", selectedLesson)

  const handleDateChange = (date) => {
    setDate(date);
  };

  useEffect(() => {
    function getItemsForCurrentDate() {
      const nowLessons = lessons.filter((lesson) => {
        const lessonDate = new Date(lesson?.date_scheduled);
        return lessonDate?.toDateString() === date?.toDateString();
      });
      
      setSelectedLessons(nowLessons);
    }

    getItemsForCurrentDate();
  }, [lessons, date]);


  


  const activities = [
    // {
    //     icon: <EventsIcon />,
    //     title: "Events",
    //     description: "8 upcoming events this week",
    //     backgroundColor: "#E8B241"
    // },
    {
      icon: <BookPlayIcon />,
      title: "Lessons",
      description: `You have ${getRemainingItemsToday(lessons)} lesson${getRemainingItemsToday(lessons) > 1 ? "s" : ""} remaining today`,
      backgroundColor: "#FA6D6D",
    },
    {
      icon: <DocumentIcon />,
      title: "Resources",
      description: "6 new resources available for you",
      backgroundColor: "#AF80CC",
    },
    {
      icon: <GradeIcon />,
      title: "Grades",
      description: "6 new resources available for you",
      backgroundColor: "#76A3CC",
    },
  ];

  return (
    <div className={classes.main}>
      <HorizontalScrollContainer heading="Heading">
        <div className="row w-100">
          <div className="col-sm-7">
            {selectedLesson.length > 0 ? selectedLesson.map((item, i) => (
              <div className={classes.todayLessonsContainer} key={i}>
                <div className="d-flex align-items-center gap-3 mb-4">
                  <div
                    className={`d-flex flex-column align-items-center justify-content-between  ${classes.timeColumn}`}
                    style={{
                      backgroundColor: checkIfCurrentDateTimeInRange(item) && "#43B061",
                      color: checkIfCurrentDateTimeInRange(item) && "#FFFFFF",
                    }}
                  >
                    <div className={classes.timeText}>
                      {convertTimeTo12HourFormat(item.start_time).timeOnly}
                    </div>
                    <div className={classes.timeText}>
                      {convertTimeTo12HourFormat(item.end_time).timeOnly}
                    </div>
                  </div>
                  <div
                    className={classes.detailsColumn}
                    style={{
                      backgroundColor: checkIfCurrentDateTimeInRange(item) && "#43B061",
                      color: checkIfCurrentDateTimeInRange(item) && "#FFFFFF",
                    }}
                  >
                    <div className="d-flex align-items-center gap-3 mb-2">
                      <div
                        className={`${classes.pillContainer} d-flex align-items-center gap-1`}
                        style={{
                          backgroundColor: checkIfCurrentDateTimeInRange(item)
                            ? "#FFFFFF"
                            : item.lesson_type === "Live Lesson"
                            ? "#E3F0E7"
                            : "#F6EEDF",
                          color:
                            item.lesson_type === "Live Lesson"
                              ? "#43B061"
                              : "#CA9835",
                        }}
                      >
                        {item.lesson_type === "Live Lesson" ? (
                          <VideoIcon />
                        ) : (
                          <BoardIcon />
                        )}{" "}
                        {item.lesson_type}
                      </div>
                      <h6>{`${item.user_created.first_name} ${item.user_created.last_name}`}</h6>
                    </div>
                    <h3>{`${item.subject} ${item.lesson_name}`}</h3>
                    <h6>
                      {convertTimeTo12HourFormat(item.start_time).time} -{" "}
                      {convertTimeTo12HourFormat(item.end_time).time}
                    </h6>
                  </div>
                </div>
              </div>
            )) : <CardContainer className={classes.cardContainer}><h3>You have no lessons scheduled for today</h3></CardContainer>}
          </div>
          <div className="col-sm-5">
            <BasicCalendar
              date={date}
              handleDateChange={handleDateChange}
              lessons={lessons}
            />
          </div>
        </div>
      </HorizontalScrollContainer>

      <HorizontalScrollContainer heading="Activity">
        {activities.map((item, i) => {
          return (
            <ActivitiesCardContainer
              key={i}
              icon={item.icon}
              title={item.title}
              description={item.description}
              backgroundColor={item.backgroundColor}
            />
          );
        })}
      </HorizontalScrollContainer>

      <HorizontalScrollContainer heading="Performance">
        {performance.map((item, i) => {
          return (
            <PerformanceCardContainer
              key={i}
              title={item.title}
              subject={item.subject}
              date={item.date}
              borderColor={item.borderColor}
            />
          );
        })}
      </HorizontalScrollContainer>
    </div>
  );
};

export default Dashboard;
